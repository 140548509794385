<template>
  <AppModal
    :id="id"
    :title="$t('application_data') || 'Application data'"
    @hide="$emit('hide')"
  >
    <b-card v-if="payload" class="mb-0">
      <b-row>
        <b-col
          cols="12"
          xl="12"
          class="d-flex justify-content-between flex-column"
        >
          <h4 class="mb-50 d-flex">
            {{ $t("application_data") || "Application data" }}
          </h4>
        </b-col>

        <b-col cols="12" xl="12">
          <table class="mt-2 mt-xl-0 w-100">
            <tr v-if="payload.award">
              <th class="pb-50">
                <feather-icon icon="CheckIcon" class="mr-75" />
                <span class="font-weight-bold">
                  {{ $t("award") || "award" }}
                </span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ payload.award.name_ar }}
              </td>
            </tr>

            <tr v-if="payload.status">
              <th class="pb-50">
                <feather-icon icon="CheckIcon" class="mr-75" />
                <span class="font-weight-bold">
                  {{ $t("status") || "status" }}
                </span>
              </th>
              <td class="pb-50 text-capitalize">
                <b-badge :variant="statusVariant(payload.status)"
                  >{{ $t(payload.status) || payload.status }}
                </b-badge>
              </td>
            </tr>

            <tr v-if="payload.hospital_direction">
              <th class="pb-50">
                <feather-icon icon="CheckIcon" class="mr-75" />
                <span class="font-weight-bold">
                  {{ $t("hospital_direction") || "hospital direction" }}
                </span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ payload.hospital_direction.name_ar }}
              </td>
            </tr>

            <tr v-if="payload.department">
              <th class="pb-50">
                <feather-icon icon="CheckIcon" class="mr-75" />
                <span class="font-weight-bold">
                  {{ $t("department") || "department" }}
                </span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ payload.department.name_ar }}
              </td>
            </tr>
            <tr v-if="payload.governorate">
              <th class="pb-50">
                <feather-icon icon="CheckIcon" class="mr-75" />
                <span class="font-weight-bold">
                  {{ $t("governorate") || "governorate" }}
                </span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ payload.governorate.name_ar }}
              </td>
            </tr>

            <hr />
            <!--  -->

            <tr v-if="payload.project_title">
              <th class="pb-50">
                <feather-icon icon="CheckIcon" class="mr-75" />
                <span class="font-weight-bold">
                  {{ $t("project_title") || "project title" }}
                </span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ payload.project_title }}
              </td>
            </tr>

            <tr v-if="payload.hospital_name">
              <th class="pb-50">
                <feather-icon icon="CheckIcon" class="mr-75" />
                <span class="font-weight-bold">
                  {{ $t("hospital_name") || "hospital name" }}
                </span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ payload.hospital_name }}
              </td>
            </tr>

            <tr v-if="payload.hospital_address">
              <th class="pb-50">
                <feather-icon icon="CheckIcon" class="mr-75" />
                <span class="font-weight-bold">
                  {{ $t("hospital_address") || "hospital address" }}
                </span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ payload.hospital_address }}
              </td>
            </tr>

            <tr v-if="payload.hospital_governorate">
              <th class="pb-50">
                <feather-icon icon="CheckIcon" class="mr-75" />
                <span class="font-weight-bold">
                  {{ $t("hospital_governorate") || "hospital governorate" }}
                </span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ payload.hospital_governorate }}
              </td>
            </tr>

            <tr v-if="payload.hospital_affiliation">
              <th class="pb-50">
                <feather-icon icon="CheckIcon" class="mr-75" />
                <span class="font-weight-bold">
                  {{ $t("hospital_affiliation") || "hospital affiliation" }}
                </span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ payload.hospital_affiliation }}
              </td>
            </tr>

            <tr v-if="payload.contact_name">
              <th class="pb-50">
                <feather-icon icon="CheckIcon" class="mr-75" />
                <span class="font-weight-bold">
                  {{ $t("contact_name") || "contact name" }}
                </span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ payload.contact_name }}
              </td>
            </tr>
            <tr v-if="payload.contact_phone">
              <th class="pb-50">
                <feather-icon icon="CheckIcon" class="mr-75" />
                <span class="font-weight-bold">
                  {{ $t("contact_phone") || "contact phone" }}
                </span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ payload.contact_phone }}
              </td>
            </tr>
            <tr v-if="payload.contact_email">
              <th class="pb-50">
                <feather-icon icon="CheckIcon" class="mr-75" />
                <span class="font-weight-bold">
                  {{ $t("contact_email") || "contact email" }}
                </span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ payload.contact_email }}
              </td>
            </tr>

            <tr v-if="payload.member">
              <th class="pb-50">
                <feather-icon icon="CheckIcon" class="mr-75" />
                <span class="font-weight-bold">
                  {{ $t("member_name") || "Member Name" }}
                </span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ payload.member.name }}
              </td>
            </tr>
            <tr v-if="payload.member">
              <th class="pb-50">
                <feather-icon icon="CheckIcon" class="mr-75" />
                <span class="font-weight-bold">
                  {{ $t("email") || "email" }}
                </span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ payload.member.email }}
              </td>
            </tr>

            <tr v-if="payload.national_id">
              <th class="pb-50">
                <feather-icon icon="CheckIcon" class="mr-75" />
                <span class="font-weight-bold">
                  {{ $t("national_id") || "national id" }}
                </span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ payload.national_id }}
              </td>
            </tr>

            <tr v-if="payload.phone_number">
              <th class="pb-50">
                <feather-icon icon="CheckIcon" class="mr-75" />
                <span class="font-weight-bold">
                  {{ $t("phone_number") || "phone number" }}
                </span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ payload.phone_number }}
              </td>
            </tr>

            <tr v-if="payload.job_title">
              <th class="pb-50">
                <feather-icon icon="CheckIcon" class="mr-75" />
                <span class="font-weight-bold">
                  {{ $t("job_title") || "job title" }}
                </span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ payload.job_title }}
              </td>
            </tr>

            <tr
              v-if="
                payload.status === 'incomplete application' &&
                payload.incomplete_application_reason
              "
            >
              <th class="pb-50">
                <feather-icon icon="CheckIcon" class="mr-75" />
                <span class="font-weight-bold">
                  {{
                    $t("incomplete_application_reason") ||
                    "incomplete application reason"
                  }}
                </span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ payload.incomplete_application_reason }}
              </td>
            </tr>

            <tr
              v-if="
                payload.status === 'rejected' && payload.rejection_reason_notes
              "
            >
              <th class="pb-50">
                <feather-icon icon="CheckIcon" class="mr-75" />
                <span class="font-weight-bold">
                  {{ $t("rejection_reason_notes") || "rejection reason notes" }}
                </span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ payload.rejection_reason_notes }}
              </td>
            </tr>

            <hr />
            <tr v-if="payload.challenges_you_faced">
              <th class="pb-50">
                <feather-icon
                  icon="Ch
                eckIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">
                  {{ $t("challenges_you_faced") }}
                </span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ payload.challenges_you_faced }}
              </td>
            </tr>

            <tr v-if="payload.brief_description_of_the_project">
              <th class="pb-50">
                <feather-icon icon="CheckIcon" class="mr-75" />
                <span class="font-weight-bold">
                  {{ $t("brief_description_of_the_project") }}
                </span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ payload.brief_description_of_the_project }}
              </td>
            </tr>

            <tr v-if="payload.project_program_objectives">
              <th class="pb-50">
                <feather-icon icon="CheckIcon" class="mr-75" />
                <span class="font-weight-bold">
                  {{ $t("project_program_objectives") }}
                </span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ payload.project_program_objectives }}
              </td>
            </tr>

            <tr v-if="payload.scope_of_implementation">
              <th class="pb-50">
                <feather-icon icon="CheckIcon" class="mr-75" />
                <span class="font-weight-bold">
                  {{ $t("scope_of_implementation") }}
                </span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ payload.scope_of_implementation }}
              </td>
            </tr>

            <tr v-if="payload.project_start_date">
              <th class="pb-50">
                <feather-icon icon="CheckIcon" class="mr-75" />
                <span class="font-weight-bold">
                  {{ $t("project_start_date") }}
                </span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ payload.project_start_date }}
              </td>
            </tr>

            <tr v-if="payload.project_end_date">
              <th class="pb-50">
                <feather-icon icon="CheckIcon" class="mr-75" />
                <span class="font-weight-bold">
                  {{ $t("project_end_date") }}
                </span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ payload.project_end_date }}
              </td>
            </tr>

            <tr v-if="payload.project_implementation">
              <th class="pb-50">
                <feather-icon icon="CheckIcon" class="mr-75" />
                <span class="font-weight-bold">
                  {{ $t("project_implementation") }}
                </span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ payload.project_implementation }}
              </td>
            </tr>

            <tr v-if="payload.challenges_during_implementation">
              <th class="pb-50">
                <feather-icon icon="CheckIcon" class="mr-75" />
                <span class="font-weight-bold">
                  {{ $t("challenges_during_implementation") }}
                </span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ payload.challenges_during_implementation }}
              </td>
            </tr>

            <tr v-if="payload.results_achieved">
              <th class="pb-50">
                <feather-icon icon="CheckIcon" class="mr-75" />
                <span class="font-weight-bold">
                  {{ $t("results_achieved") }}
                </span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ payload.results_achieved }}
              </td>
            </tr>

            <tr v-if="payload.project_impact">
              <th class="pb-50">
                <feather-icon icon="CheckIcon" class="mr-75" />
                <span class="font-weight-bold">
                  {{ $t("project_impact") }}
                </span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ payload.project_impact }}
              </td>
            </tr>

            <tr v-if="payload.project_benefited">
              <th class="pb-50">
                <feather-icon icon="CheckIcon" class="mr-75" />
                <span class="font-weight-bold">
                  {{ $t("project_benefited") }}
                </span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ payload.project_benefited }}
              </td>
            </tr>

            <tr v-if="payload.commitment_situation">
              <th class="pb-50">
                <feather-icon icon="CheckIcon" class="mr-75" />
                <span class="font-weight-bold">
                  {{ $t("commitment_situation") }}
                </span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ payload.commitment_situation }}
              </td>
            </tr>

            <tr v-if="payload.communication_situation">
              <th class="pb-50">
                <feather-icon icon="CheckIcon" class="mr-75" />
                <span class="font-weight-bold">
                  {{ $t("communication_situation") }}
                </span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ payload.communication_situation }}
              </td>
            </tr>

            <tr v-if="payload.department && payload.department.name_ar">
              <th class="pb-50">
                <feather-icon icon="CheckIcon" class="mr-75" />
                <span class="font-weight-bold">
                  {{ $t("department") }}
                </span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ payload.department.name_ar }}
              </td>
            </tr>

            <tr v-if="payload.department_management">
              <th class="pb-50">
                <feather-icon icon="CheckIcon" class="mr-75" />
                <span class="font-weight-bold">
                  {{ $t("department_management") }}
                </span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ payload.department_management }}
              </td>
            </tr>

            <tr v-if="payload.governorate && payload.governorate.name_ar">
              <th class="pb-50">
                <feather-icon icon="CheckIcon" class="mr-75" />
                <span class="font-weight-bold">
                  {{ $t("governorate") }}
                </span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ payload.governorate.name_ar }}
              </td>
            </tr>

            <tr v-if="payload.hospital_name">
              <th class="pb-50">
                <feather-icon icon="CheckIcon" class="mr-75" />
                <span class="font-weight-bold">
                  {{ $t("hospital_name") }}
                </span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ payload.hospital_name }}
              </td>
            </tr>

            <tr
              v-if="
                payload.hospital_direction && payload.hospital_direction.name_ar
              "
            >
              <th class="pb-50">
                <feather-icon icon="CheckIcon" class="mr-75" />
                <span class="font-weight-bold">
                  {{ $t("hospital_direction") }}
                </span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ payload.hospital_direction.name_ar }}
              </td>
            </tr>

            <tr v-if="payload.job_title">
              <th class="pb-50">
                <feather-icon icon="CheckIcon" class="mr-75" />
                <span class="font-weight-bold">
                  {{ $t("job_title") }}
                </span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ payload.job_title }}
              </td>
            </tr>

            <tr v-if="payload.nomination_full_description">
              <th class="pb-50">
                <feather-icon icon="CheckIcon" class="mr-75" />
                <span class="font-weight-bold">
                  {{ $t("nomination_full_description") }}
                </span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ payload.nomination_full_description }}
              </td>
            </tr>

            <tr v-if="payload.occupation">
              <th class="pb-50">
                <feather-icon icon="CheckIcon" class="mr-75" />
                <span class="font-weight-bold">
                  {{ $t("occupation") }}
                </span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ payload.occupation }}
              </td>
            </tr>

            <tr v-if="payload.profession_license_number">
              <th class="pb-50">
                <feather-icon icon="CheckIcon" class="mr-75" />
                <span class="font-weight-bold">
                  {{ $t("profession_license_number") }}
                </span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ payload.profession_license_number }}
              </td>
            </tr>

            <tr v-if="payload.situation_for_contribution">
              <th class="pb-50">
                <feather-icon icon="CheckIcon" class="mr-75" />
                <span class="font-weight-bold">
                  {{ $t("situation_for_contribution") }}
                </span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ payload.situation_for_contribution }}
              </td>
            </tr>

            <tr v-if="payload.situation_for_educating_patient">
              <th class="pb-50">
                <feather-icon icon="CheckIcon" class="mr-75" />
                <span class="font-weight-bold">
                  {{ $t("situation_for_educating_patient") }}
                </span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ payload.situation_for_educating_patient }}
              </td>
            </tr>

            <tr v-if="payload.situation_for_innovative_solutions">
              <th class="pb-50">
                <feather-icon icon="CheckIcon" class="mr-75" />
                <span class="font-weight-bold">
                  {{ $t("situation_for_innovative_solutions") }}
                </span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ payload.situation_for_innovative_solutions }}
              </td>
            </tr>

            <tr v-if="payload.situation_for_kindness">
              <th class="pb-50">
                <feather-icon icon="CheckIcon" class="mr-75" />
                <span class="font-weight-bold">
                  {{ $t("situation_for_kindness") }}
                </span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ payload.situation_for_kindness }}
              </td>
            </tr>
          </table>
        </b-col>
      </b-row>
    </b-card>
    <!-- <ApplicationDocuments
      id="application-documents-modal"
      :application="payload"
    /> -->
  </AppModal>
</template>

<script>
import {
  BCard,
  BButton,
  BAvatar,
  BRow,
  BCol,
  BBadge,
  BLink,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
// import useUsersList from "./useUsersList";
import serverResponseCheck from "@/components/hoc/serverResponseCheck.vue";
import ApplicationDocuments from "./ApplicationDocuments.vue";

export default {
  components: {
    BCard,
    BButton,
    BLink,
    BRow,
    BCol,
    BAvatar,
    BBadge,
    serverResponseCheck,
    ApplicationDocuments,
  },
  props: {
    payload: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    id: {
      type: String,
      required: true,
    },
  },
  computed: {
    isRTL() {
      return window.localStorage.getItem("language") === "ar";
    },
    statusVariant() {
      return (status) =>
        ({
          "on progress": "warning",
          interview: "warning",
          "incomplete application": "warning",
          registered: "primary",
          paid: "primary",
          approved: "success",
          pending: "secondary",
          rejected: "danger",
          refused: "danger",
          denied: "danger",
        }[status] || "secondary");
    },
    documents() {
      return this.$store.state.members.memberDocuments;
    },
    isLoading() {
      return this.$store.state.members.loadingDocuments;
    },
  },
  methods: {
    openDocumentsModal(user) {
      this.$bvModal.show("application-documents-modal");
    },
  },
  // setup() {
  //   const { resolveUserRoleVariant } = useUsersList();
  //   return {
  //     avatarText,
  //     resolveUserRoleVariant
  //   };
  // }
};
</script>

<style scoped>
.text-wrap {
  line-break: anywhere;
}
</style>