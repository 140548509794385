<template>
  <AppModal :id="id" size="lg" @hidden="$emit('hidden')">
    <b-overlay :show="!payload">
      <div v-if="payload">
        <header class="d-flex">
          <h5 class="mb-2 mr-50">{{ $t("documents") || "Documents" }}:</h5>
          ( {{ documents_count ? documents_count : "0" }}
          {{
            documents_count && Number(documents_count) > 1
              ? $t("documents") || "Documents"
              : $t("document") || "Document"
          }}
          )
        </header>
        <p v-if="!documents_count">
          {{ $t("no_documents") || "No documents" }}
        </p>
        <article
          v-for="(document, index) in Object.keys(payload.documents)"
          :key="index"
          class="d-flex flex-column align-items-center mb-2"
          :class="documents_count - 1 !== index ? 'border-bottom pb-3' : ''"
        >
          <section>
            <h5 class="text-center">{{ $t(document) || document }}</h5>
            <div
              v-for="(internalDoc, index) in getDocuments(document)"
              :key="index"
            >
              <b-img-lazy
                v-if="
                  document &&
                  internalDoc &&
                  (internalDoc.slice(-3).toLowerCase() === 'jpg' ||
                    internalDoc.slice(-4).toLowerCase() === 'jpeg' ||
                    internalDoc.slice(-3).toLowerCase() === 'png')
                "
                :alt="document"
                block
                fluid-grow
                :src="internalDoc"
              />
              <span v-else class="file">
                <feather-icon class="p-1" icon="FileIcon" size="100" />
              </span>

              <div class="mt-1 d-flex align-items-center justify-content-center w-100">
                <a
                  v-if="internalDoc"
                  :href="internalDoc"
                  target="_blank"
                  class="mx-2"
                  download
                >
                  <feather-icon
                    class="cursor-pointer text-primary"
                    icon="DownloadIcon"
                    size="20"
                  />
                  <!-- @click="downloadHandler" -->
                  <span class="sr-only">
                    {{ $t("download_file") || "download file" }}
                  </span>
                </a>
              </div>
            </div>
          </section>
        </article>
      </div>
    </b-overlay>
  </AppModal>
</template>

<script>
import { BImgLazy, BOverlay, BAvatar, BBadge } from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";

export default {
  components: { BImgLazy, BOverlay, BAvatar, BBadge },
  props: {
    id: {
      type: String,
      required: false,
      default: "documents-wrapper-modal",
    },
    payload: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  methods: {
    avatarText,
  },
  computed: {
    isRTL() {
      return window.localStorage.getItem("language") === "ar";
    },
    statusVariant() {
      return (status) =>
        ({
          "on progress": "warning",
          interview: "warning",
          "incomplete payload": "warning",
          registered: "primary",
          paid: "primary",
          approved: "success",
          pending: "secondary",
          rejected: "danger",
          refused: "danger",
          denied: "danger",
        }[status] || "secondary");
    },
    documents_count() {
      return this.payload.documents
        ? Object.values(this.payload.documents).flat().length
        : 0;
    },
    getDocuments() {
      return (key) => this.payload.documents[key];
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
