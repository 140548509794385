<template>
  <AppForm
    :id="id"
    :title="$t('score') || 'Score'"
    :cancel-label="$t('cancel') || 'Cancel'"
    :submit-label="$t('submit') || 'Submit'"
    :is-loading="isLoading"
    :overlay-opacity="1"
    :variant="'primary'"
    :errorsBag="errorsBag"
    @submit.prevent="submitHandler"
    @hide="hideModal"
  >
    <article v-for="(kpi, index) in kpis" :key="kpi.id">
      <div class="row align-items-center">
        <span class="col-6"> {{ kpi.name_ar }} </span>
        <AppInput
          :data-test="`kpi_max_score-input-${kpi.id}`"
          type="number"
          class="col-6 mb-2 mb-md-0 d-inline-block w-auto"
          :placeholder="$t('score') || 'score'"
          group-classes="mb-0"
          @change="changeKpiScore($event, index, kpi.uuid)"
        />
      </div>
      <hr />
    </article>
  </AppForm>
</template>

<script>
import { AppForm, AppInput } from "@/components/form/index";

export default {
  name: "ScoreApplicationModal",
  components: {
    AppForm,
    AppInput,
  },
  props: {
    id: {
      type: String,
      required: false,
      default: "warning-modal",
    },
    payload: {
      type: [Object, Array],
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      scores: [],
    };
  },
  computed: {
    kpis() {
      return this.$store.state.kpi.data;
    },
    isLoading() {
      return (
        this.$store.state.kpi.isLoading.fetching ||
        this.$store.state.application_individual.isLoading.updating
      );
    },
    errorsBag() {
      return this.$store.state.application_individual.errors;
    },
  },
  mounted() {
    this.$store.dispatch("kpi/fetchAllData");
  },
  methods: {
    submitHandler() {
      this.$store.dispatch("application_individual/scoreApplication", {
        application: this.payload.uuid,
        award: this.payload.award.uuid,
        scores: this.scores,
      });
    },
    hideModal() {
      this.$root.$emit("bv::hide::modal", this.id);
      this.$emit("hide");
    },
    changeKpiScore(value, index, uuid) {
      this.$set(this.scores, index, {
        uuid: uuid,
        score: value,
      });
    },
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
.mi-end-2 {
  margin-inline-end: 1rem;
}
</style>
