<template>
  <b-overlay :show="!governorates || !governorates.length" opacity="1">
    <b-tabs pills v-if="governorates && governorates.length">
      <b-tab
              v-if="userCan('application_team-access')"
              active
              :title="$t('team') || 'team'"
              :active="
          !userCan('application_individual-access') &&
          userCan('application_team-access')
        "
      >
        <AppTable
                store-module="application_team"
                :table-fields="getTeamOptions.tableFields"
                :filters="getTeamOptions.filters"
                :actions="getTeamOptions.actions"
                :table-header="getTeamOptions.tableHeader"
                @viewApplication="viewApplicationHandler"
                @downloadApplication="downloadApplicationHandler"
                @score="scoreHandler"
        />
      </b-tab>
      <b-tab
        v-if="userCan('application_individual-access')"
        :title="$t('individual') || 'individual'"
      >
        <AppTable
          store-module="application_individual"
          :table-fields="getIndividualOptions.tableFields"
          :filters="getIndividualOptions.filters"
          :actions="getIndividualOptions.actions"
          :table-header="getIndividualOptions.tableHeader"
          @viewApplication="viewApplicationHandler"
          @downloadApplication="downloadApplicationHandler"
          @score="scoreHandler"
        />
      </b-tab>

    </b-tabs>
    <ModalScore
      id="modal-score"
      :payload="activeItem"
      @hide="activeItem = null"
    />
    <ApplicationViewModal
      id="application-view-modal"
      :payload="activeItem"
      @hide="activeItem = null"
    />

    <ApplicationDocuments
      id="application-download-modal"
      :payload="activeItem"
      @hide="activeItem = null"
    />
  </b-overlay>
</template>

<script>
import { BTabs, BTab, BOverlay } from "bootstrap-vue";
import AppTable from "@/components/table/AppTable.vue";
import i18n from "@/libs/i18n/index";
import userCan from "@/mixins/UserCan";
import ModalScore from "./modalScore.vue";
import ApplicationViewModal from "./ApplicationViewModal.vue";
import ApplicationDocuments from "./ApplicationDocuments.vue";

export default {
  name: "Applications",
  components: {
    BOverlay,
    AppTable,
    BTabs,
    BTab,
    ModalScore,
    ApplicationViewModal,
    ApplicationDocuments,
  },
  mounted() {
    this.$store.dispatch("rejection_reasons/fetchData");
    this.$store.dispatch("governorates/fetchAllData");
    this.$store.dispatch("departments/fetchAllData");
    this.$store.dispatch("hospitalDirection/fetchAllData");
  },
  computed: {
    isRTL() {
      return window.localStorage.getItem("language") === "ar";
    },
    governorates() {
      const allGovernoratesOptions = this.$store.state.governorates.data?.map(
        (governorate) => {
          return {
            value: governorate.uuid,
            label: this.isRTL
              ? governorate.name_ar || governorate.name
              : governorate.name || governorate.name_ar,
          };
        }
      );
      return allGovernoratesOptions || [];
    },
    departments() {
      const allDepartmentsOptions = this.$store.state.departments.data?.map(
        (department) => {
          return {
            value: department.uuid,
            label: this.isRTL
              ? department.name_ar || department.name
              : department.name || department.name_ar,
          };
        }
      );
      return allDepartmentsOptions || [];
    },
    hospitalDirections() {
      const allHospitalDirectionsOptions =
        this.$store.state.hospitalDirection.data?.map((hospitalDirection) => {
          return {
            value: hospitalDirection.uuid,
            label: this.isRTL
              ? hospitalDirection.name_ar || hospitalDirection.name
              : hospitalDirection.name || hospitalDirection.name_ar,
          };
        });
      return allHospitalDirectionsOptions || [];
    },
    getIndividualOptions() {
      return {
        tableFields: [
          { key: "edit_delete", label: i18n.t("actions") || "actions" },
          { key: "status", label: i18n.t("status") || "status" },
          { key: "application_number", label: i18n.t("id") || "id" },
          {
            key: "candidate_name",
            label: i18n.t("candidate_name") || "candidate_name",
          },
          { key: "email", label: i18n.t("email") || "email" },
          { key: "job_title", label: i18n.t("job_title") || "job_title" },
          { key: "national_id", label: i18n.t("national_id") || "national_id" },
          {
            key: "application_governorate",
            label: i18n.t("governorate") || "governorate",
          },
          {
            key: "phone_number",
            label: i18n.t("phone_number") || "phone_number",
          },
          {
            key: "rejection_reason",
            label: i18n.t("rejection_reason") || "rejection_reason",
          },
          { key: "notes", label: i18n.t("notes") || "notes" },
        ],
        filters: [
          {
            name: "status",
            options: [
              { label: i18n.t("all") || "All", value: null },
              { label: i18n.t("pending") || "pending", value: "pending" },
              {
                label:
                  i18n.t("incomplete_application") || "incomplete application",
                value: "incomplete application",
              },
              { label: i18n.t("rejected") || "rejected", value: "rejected" },
              { label: i18n.t("approved") || "approved", value: "approved" },
              { label: i18n.t("judged") || "judged", value: "judged" },
            ],
            componentType: "AppSelect",
          },
          {
            name: "governorate",
            options: [
              { label: i18n.t("all") || "All", value: null },
              ...this.governorates,
            ],
            componentType: "AppSelect",
          },
          {
            name: "department",
            options: [
              { label: i18n.t("all") || "All", value: null },
              ...this.departments,
            ],
            componentType: "AppSelect",
          },
          {
            name: "hospital_direction",
            options: [
              { label: i18n.t("all") || "All", value: null },
              ...this.hospitalDirections,
            ],
            componentType: "AppSelect",
          },
          { name: "createdAt", componentType: "AppDatePicker" },
        ],
        actions: [
          {
            label: i18n.t("view_application") || "View Application",
            variant: "primary",
            event: "viewApplication",
            permission: "view",
            disableModal: true,
          },
          {
            label: i18n.t("download_application") || "View Application",
            variant: "primary",
            event: "downloadApplication",
            permission: "download",
            disableModal: true,
          },
          {
            label: i18n.t("approve") || "approve",
            variant: "success",
            event: "approve",
            storeAction: "approveHandler",
            permission: "approve",
            message:
              i18n.t("mark_the_application_approved") ||
              "mark the application approved",
            message_key: "candidate_name",
            condition: [
              {
                key: "status",
                value: "approved",
              },
            ],
          },
          {
            label: i18n.t("incomplete") || "incomplete",
            variant: "warning",
            event: "incomplete",
            storeAction: "incompleteHandler",
            displayNotes: true,
            notesKey: "incomplete_application_reason",
            permission: "incomplete_application",
            message:
              i18n.t("mark_the_application_incomplete") ||
              "mark the application incomplete",
            message_key: "candidate_name",
            condition: [
              {
                key: "status",
                value: "incomplete application",
              },
            ],
          },
          {
            label: i18n.t("reject") || "reject",
            variant: "danger",
            event: "reject",
            storeAction: "rejectHandler",
            displayNotes: true,
            notesKey: "rejection_reason_notes",
            displayRejectionReasons: true,
            rejectionReasonsKey: "rejection_reason",
            permission: "rejected",
            message:
              i18n.t("mark_the_application_rejected") ||
              "mark the application rejected",
            message_key: "candidate_name",
            condition: [
              {
                key: "status",
                value: "rejected",
              },
            ],
          },
          {
            label: i18n.t("score") || "score",
            variant: "primary",
            event: "score",
            storeAction: "scoreHandler",
            permission: "score",
            disableModal: true,
          },
        ],
        tableHeader: {
          addNewBtn: false,
          multiKeySearch: true,
          initialSelected: "candidate_name",
          searchByOptions: [
            {
              text: this.$t("candidate_name"),
              value: "candidate_name",
            },
            {
              text: this.$t("application_number"),
              value: "application_number",
            },
            {
              text: this.$t("department_management_filter"),
              value: "department_management",
            },
            {
              text: this.$t("email"),
              value: "email",
            },
            {
              text: this.$t("hospital_name"),
              value: "hospital_name",
            },
            {
              text: this.$t("job_title"),
              value: "job_title",
            },
            {
              text: this.$t("national_id"),
              value: "national_id",
            },
            {
              text: this.$t("occupation"),
              value: "occupation",
            },
            {
              text: this.$t("phone_number"),
              value: "phone_number",
            },
            {
              text: this.$t("profession_license_number"),
              value: "profession_license_number",
            },
          ],
        },
      };
    },
    getTeamOptions() {
      return {
        tableFields: [
          { key: "edit_delete", label: i18n.t("edit_delete") || "Edit/Delete" },
          { key: "status", label: i18n.t("status") || "status" },
          { key: "application_number", label: i18n.t("id") || "id" },
          {
            key: "project_title",
            label: i18n.t("project_title") || "project_title",
          },
          {
            key: "contact_name",
            label: i18n.t("contact_name") || "contact_name",
          },
          {
            key: "contact_email",
            label: i18n.t("contact_email") || "contact_email",
          },
          {
            key: "contact_phone",
            label: i18n.t("contact_phone") || "contact_phone",
          },
          {
            key: "hospital_name",
            label: i18n.t("hospital_name") || "hospital_name",
          },
          {
            key: "hospital_address",
            label: i18n.t("hospital_address"),
          },
          {
            key: "hospital_governorate",
            label: i18n.t("hospital_governorate") || "governorate",
          },
          {
            key: "hospital_direction",
            label: i18n.t("hospital_direction") || "hospital_direction",
          },
          {
            key: "rejection_reason",
            label: i18n.t("rejection_reason") || "rejection_reason",
          },
          { key: "notes", label: i18n.t("notes") || "notes" },
        ],
        filters: [
          {
            name: "status",
            options: [
              { label: i18n.t("pending") || "pending", value: "pending" },
              {
                label:
                  i18n.t("incomplete_application") || "incomplete application",
                value: "incomplete application",
              },
              { label: i18n.t("rejected") || "rejected", value: "rejected" },
              { label: i18n.t("approved") || "approved", value: "approved" },
              { label: i18n.t("judged") || "judged", value: "judged" },
            ],
            componentType: "AppSelect",
          },
          {
            name: "governorate",
            options: [
              { label: i18n.t("all") || "All", value: null },
              ...this.governorates,
            ],
            componentType: "AppSelect",
          },
          {
            name: "hospital_direction",
            options: [
              { label: i18n.t("all") || "All", value: null },
              ...this.hospitalDirections,
            ],
            componentType: "AppSelect",
          },
          { name: "createdAt", componentType: "AppDatePicker" },
        ],
        actions: [
          {
            label: i18n.t("view_application") || "View Application",
            variant: "primary",
            event: "viewApplication",
            permission: "view",
            disableModal: true,
          },
          {
            label: i18n.t("download_application") || "View Application",
            variant: "primary",
            event: "downloadApplication",
            permission: "download",
            disableModal: true,
          },
          {
            label: i18n.t("approve") || "approve",
            variant: "success",
            event: "approve",
            storeAction: "approveHandler",
            permission: "approve",
            message:
              i18n.t("mark_the_application_approved") ||
              "mark the application approved",
            message_key: "project_title",
            condition: [
              {
                key: "status",
                value: "approved",
              },
            ],
          },
          {
            label: i18n.t("incomplete") || "incomplete",
            variant: "warning",
            event: "incomplete",
            storeAction: "incompleteHandler",
            displayNotes: true,
            notesKey: "incomplete_application_reason",
            permission: "incomplete_application",
            message:
              i18n.t("mark_the_application_incomplete") ||
              "mark the application incomplete",
            message_key: "project_title",
            condition: [
              {
                key: "status",
                value: "incomplete application",
              },
            ],
          },
          {
            label: i18n.t("reject") || "reject",
            variant: "danger",
            event: "reject",
            storeAction: "rejectHandler",
            displayNotes: true,
            notesKey: "rejection_reason_notes",
            displayRejectionReasons: true,
            rejectionReasonsKey: "rejection_reason",
            permission: "rejected",
            message:
              i18n.t("mark_the_application_rejected") ||
              "mark the application rejected",
            message_key: "project_title",
            condition: [
              {
                key: "status",
                value: "rejected",
              },
            ],
          },
          {
            label: i18n.t("score") || "score",
            variant: "primary",
            event: "score",
            storeAction: "scoreHandler",
            permission: "score",
            disableModal: true,
          },
        ],
        tableHeader: {
          addNewBtn: false,
          multiKeySearch: true,
          initialSelected: "candidate_name",
          searchByOptions: [
            {
              text: this.$t("candidate_name"),
              value: "candidate_name",
            },
            {
              text: this.$t("application_number"),
              value: "application_number",
            },
            {
              text: this.$t("department_management_filter"),
              value: "department_management",
            },
            {
              text: this.$t("email"),
              value: "email",
            },
            {
              text: this.$t("hospital_name"),
              value: "hospital_name",
            },
            {
              text: this.$t("job_title"),
              value: "job_title",
            },
            {
              text: this.$t("national_id"),
              value: "national_id",
            },
            {
              text: this.$t("occupation"),
              value: "occupation",
            },
            {
              text: this.$t("phone_number"),
              value: "phone_number",
            },
            {
              text: this.$t("profession_license_number"),
              value: "profession_license_number",
            },
          ],
        },
      };
    },
  },
  methods: {
    userCan,
    scoreHandler({ action, activeItem }) {
      this.activeItem = activeItem;
      this.$nextTick(() => {
        this.$root.$emit("bv::show::modal", "modal-score");
      });
    },
    viewApplicationHandler({ action, activeItem }) {
      const routeData = this.$router.resolve({
        name: "application",
        params: {
          id: activeItem.application_number || activeItem.id,
          type: activeItem.candidate_name ? "individual" : "team",
        },
        query: {
          type: activeItem.candidate_name ? "individual" : "team",
        },
      });

      window.open(routeData.href, "_blank");
    },
    downloadApplicationHandler({ action, activeItem }) {
      this.activeItem = activeItem;
      this.$nextTick(() => {
        this.$root.$emit("bv::show::modal", "application-download-modal");
      });
    },
  },
  data() {
    return {
      activeItem: null,
    };
  },
};
</script>

<style lang="scss" scoped>
::v-deep tbody > tr:first-of-type > td:first-of-type {
  min-width: 200px;
}

::v-deep thead th:last-of-type {
  width: 300px;
  min-width: 300px;
}
</style>
